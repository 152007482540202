html,
body,
#root,
#root > div,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #2b2b2b #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #2b2b2b;
  border: 4px solid #ffffff;
}